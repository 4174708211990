import { useCallback, useMemo } from "react";
import debounce from "lodash.debounce";
import client from "../client";

const useUsers = () => {
    // GET USERS
    const getUsers = useCallback(async (value: string) => {
        const params = { searchString: value };
        const res = await client.get("user-management/users", { params: params });

        return res.data.users.map((user: any) => {
            return {
                label: <span>{user.name} - {user.email}</span>,
                value: user
            };
        });
    }, []);

    const loadUsersOptions = useMemo(() => {
        return debounce((value: any, callback: any) => {
            getUsers(value).then((options) => callback(options));
        }, 1000);
    }, [getUsers]);

    return { loadUsersOptions };
};

export default useUsers;
